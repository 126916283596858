@use '../../../src/assets/sass/settings/variables/colors';

.avatar {
  border-radius: 50%;
  overflow: hidden;
  width: 35px;
  height: 35px;
  line-height: 35px;

  .image-holder {
    height: 35px;
  }

  &--xsmall {
    flex: 0 0 28px;
    height: 28px;
    width: 28px;
    line-height: 28px;

    .image-holder {
      height: 28px;
    }
  }

  &--small {
    flex: 0 0 30px;
    height: 30px;
    width: 30px;
    line-height: 30px;

    .image-holder {
      height: 30px;
    }
  }

  &--big {
    flex: 0 0 40px;
    height: 40px;
    width: 40px;
    line-height: 40px;

    .image-holder {
      height: 40px;
    }
  }

  &--huge {
    flex: 0 0 75px;
    height: 75px;
    width: 75px;
    line-height: 75px;

    .image-holder {
      height: 75px;
    }
  }

  .image-holder {
    display: block;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__fallback {
    width: 100%;
    height: 100%;
    background-color: colors.$color--darker-gray;
    color: colors.$color--white;
    text-align: center;

    span {
      pointer-events: none;
      user-select: none
    }
  }

  &--border {
    border: 3px solid colors.$color--white;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05), 0 5px 20px rgba(0, 0, 0, 0.05);
  }
}
