@use '../../../assets/sass/settings/config';
@use '../../../assets/sass/settings/variables/colors';

.header-logo {
  &:focus:not(:active) {
    border: 0;
    outline: none;
    border-bottom: 3px solid colors.$color--radical-red;
  }
}
