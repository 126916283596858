@use '../../../assets/sass/settings/config';
@use '../../../assets/sass/pages/page-popup';
@use '../../../assets/sass/components/dropdown';
@use '../../../assets/sass/components/nav-user';
@use '../../../assets/sass/settings/variables/layout';

.nav--user {
  .user__avatar {

    @media #{layout.$breakpoint-m-max} {
      height: 40px;
      width: 40px;
    }

    img:focus,
    img.focus {
      outline: none !important;
    }
  }
}
