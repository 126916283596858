@use '../settings/mixins/mixins';
@use '../settings/variables/layout';
@use '../settings/variables/colors';

.region--content {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  scrollbar-width: none;

  &--pampas {
    background-color: colors.$color--pampas;
  }

  &--flex {
    @include mixins.flexbox ($direction: column);
  }

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  .region__inner {
    margin: {
      left: auto;
      right: auto;
    }
    max-width: layout.$l-content-width;
    padding: {
      left: 15px;
      right: 15px;
    }

    @media #{layout.$breakpoint-m-max} {
      padding: {
        bottom: 15px;
        top: 15px;
      }
    }

    @media #{layout.$breakpoint-l} {
      padding: {
        bottom: 33px;
        top: 33px;
      }
    }
  }

  input.disabled,
  button.disabled {
    opacity: 0.5;

    &:hover {
      opacity: 0.5;
    }
  }
}

.content__header {
  @include mixins.flexbox ($align: center, $justify: space-between);

  &.border-bottom {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
}

.region--sidebar-first {
  .region__inner {
    @media #{layout.$breakpoint-s} {
      background-color: colors.$color--white;
    }

    @media #{layout.$breakpoint-m-min} {
      @include mixins.flexbox ($justify: space-between);
    }
  }
}
