@use '../settings/mixins/mixins';
@use '../settings/variables/layout';
@use '../settings/variables/colors';
@use '../settings/variables/typography';
@use '../settings/variables/icons';

.region--header {
  position: relative;
  transition: 0.3s all ease-in-out;

  .region__inner {
    @include mixins.flexbox ($align: center, $wrap: wrap);

    .dropdown {
      @media #{layout.$breakpoint-m-max} {
        .button--dropdown{
          width: 100%;
        }
      }
    }
    background-color: colors.$color--white;
    border-bottom: {
      color: colors.$color--quill-gray;
      style: solid;
    }

    @media #{layout.$breakpoint-m-max} {
      padding: {
        left: 15px;
        right: 15px;
      }
    }

    @media #{layout.$breakpoint-l} {
      padding: {
        left: 20px;
        right: 20px;
      }
      position: relative;
    }
  }

  .header__mobile-row {
    @include mixins.flexbox($justify: center);
    position: relative;

    @media #{layout.$breakpoint-m-max} {
      @include mixins.border ($side: top);
      flex: 0 0 100vw;
      margin: {
        left: -15px;
        right: -15px;
        top: 13px;
      }
      order: 2;
      padding: {
        left: 15px;
        right: 15px;
        top: 13px;
      }

      .form__item--search {
        order: -1;
      }
    }

    @media #{layout.$breakpoint-l} {
      flex: 1;
    }

    @media #{layout.$breakpoint-m-max} {
      @include mixins.flexbox($justify: space-between);
    }
  }

  .header--primary {
    border-width: 2px;
    @include mixins.flexbox ($direction: row, $justify: space-between);
    z-index: 3;
    padding: {
      bottom: 9px;
      top: 9px;
    }
    position: relative;
  }

  .trigger--menu-open {
    @media #{layout.$breakpoint-m-max} {
      background: {
        image: url(icons.$icon--menu-open);
        repeat: no-repeat;
        size: 100%;
      }
      font-size: 0;
      height: 15px;
      margin: {
        left: auto;
        right: 20px;
      }
      width: 15px;
    }
  }

  .header--secondary {
    border-width: 1px;
    padding: {
      bottom: 13px;
      top: 12px;
    }
    z-index: 3;

    @media #{layout.$breakpoint-m-max} {
      position: relative;

      & > .button {
        margin-left: auto;
      }
    }
  }

  .header__title {
    @include mixins.fontStyle ($fontSize: 20px, $fontWeight: typography.$fw--bold, $fontColor: colors.$color--black);
    margin-right: 10px;
  }

  .header__text {
    color: colors.$color--dusty-gray;
    margin: {
      right: 10px;
      top: 3px;
    }
  }

  .button--filters {
    margin-right: 10px;
  }

  .filter-assignee {
    @include mixins.flexbox ($align: center);
    height: 100%;
    margin: 0 10px;

    @media (max-width: layout.$header-width-m-max) {
      display: none;
    }
  }

  .filter-assignee__item {
    @include mixins.fontStyle ($fontSize: 13px, $fontWeight: typography.$fw--bold, $fontColor: colors.$color--friar-grey);
    border-radius: 4px;
    cursor: pointer;
    padding: 10px;
    text-transform: uppercase;
    transition: all 0.3s ease;

    &:hover {
      color: colors.$color--radical-red;
    }

    &.is-active {
      background-color: colors.$color--pippin;
      color: colors.$color--radical-red;
    }
  }

  .dropdown--sort {
    margin-left: auto;
  }

  .header--tertiary {
    border-width: 0;
    left: 0;
    padding: {
      bottom: 15px;
      top: 15px;
    }
    position: absolute;
    right: 0;
    transition: all 0.3s ease-in-out;
    z-index: -1;

    @media #{layout.$breakpoint-s} {
      @include mixins.flexbox ($align: flex-start, $direction: column);
      top: -400%;
    }

    @media #{layout.$breakpoint-m-min} {
      top: -200%;
    }

    &.open {
      top: 100%;
      @media #{layout.$breakpoint-s} {
        height: 100vh;
      }
    }

    .dropdown {
      @media #{layout.$breakpoint-s} {
        margin-bottom: 18px;
      }

      @media #{layout.$breakpoint-m-min} {
        margin-right: 10px;
      }
    }

    .filter-footer {
      @include mixins.flexbox ($align: center, $justify: stretch);

      .form__item--search {
        margin-right: 15px;
      }
      @media #{layout.$breakpoint-s} {
        @include mixins.border ($side: top);
        @include mixins.flexbox ($align: center, $justify: space-between);
        margin-left: -15px;
        padding: {
          left: 0;
          right: 15px;
          top: 15px;
        }
        width: 100vw;
      }

      @media #{layout.$breakpoint-m-min} {
        margin-left: auto;
      }

      @media (max-width: layout.$header-width-m-max) {
        .form__item--search {
          margin-right: 10px;
        }
      }
    }

    .button--reset {
      @media #{layout.$breakpoint-s} {
        margin-right: auto;
      }
    }
  }
}

.header--group {
  @include mixins.flexbox ($align: center);
}
