@use '../../../assets/sass/global/header';
@use '../../../assets/sass/settings/variables/layout';

.app--header {
  z-index: 8;

  @media #{layout.$breakpoint-m-max} {
    z-index: 10;
  }
}

.region--header .header--primary {
  z-index: 1;
}
