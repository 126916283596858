@use '../settings/mixins/mixins';
@use '../settings/variables/layout';
@use '../settings/variables/colors';
@use '../settings/variables/typography';

.nav--user {
  position: relative;

  .user__avatar {
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;

    @media #{layout.$breakpoint-m-max} {
      height: 30px;
      width: 30px;
    }

    @media #{layout.$breakpoint-l} {
      height: 40px;
      width: 40px;
    }

    img {
      max-width: 100%;
      object-position: center;
    }
  }

  .nav__list {
    background-color: colors.$color--white;
    border-radius: 7px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05), 0 5px 20px rgba(0, 0, 0, 0.05);
    min-width: 175px;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: calc(100% + 5px);
    transition: all 0.1s ease;
    z-index: 2;
    height: 0;

    &.open {
      height: auto;
      z-index: 20;
      cursor: pointer;
    }
  }

  .nav__item:not(:last-of-type) {
    @include mixins.border ($side: bottom);
  }

  .nav__link {
    @include mixins.fontStyle ($fontSize: 13px, $fontWeight: typography.$fw--medium, $lineHeight: 40px, $fontColor: colors.$color--friar-grey);
    display: block;
    padding: {
      left: 15px;
      right: 15px;
    }
    text-decoration: none;

    @media #{layout.$breakpoint-l} {
      &:hover {
        background-color: colors.$color--pampas;
        color: colors.$color--radical-red;
      }
    }
  }
}
