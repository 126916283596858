@use '../settings/mixins/mixins';
@use '../settings/variables/layout';
@use '../settings/variables/colors';

.page--requests {
  background-color: colors.$color--pampas;

  &:before {
    @media #{layout.$breakpoint-m-max} {
      top: 50px;
    }
  }
}

.request-list {
  background-color: colors.$color--pampas;
}

.request-list--list-view {
  overflow: hidden;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media #{layout.$breakpoint-m-max} {
    padding: 5px 5px 56px;
  }

  @media #{layout.$breakpoint-l} {
    padding: 10px 20px;
  }
}

.request-list--kanban-view {
  @include mixins.flexbox();
  min-height: calc(100vh - 120px);
  overflow-x: scroll;
}

.kanban__col {
  transition: background-color .2s ease-in-out;
  padding: 20px 10px;

  &:not(:last-of-type) {
    @include mixins.border ($side: right);
  }

  &--hover {
    background-color: colors.$color--cararra;
  }

  @media #{layout.$breakpoint-m} {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media #{layout.$breakpoint-l} {
    flex: 0 0 33.3%;
    max-width: 33.3%;
    min-width: 481px;
    height: calc(100vh - 135px);
  }

  @media #{layout.$breakpoint-s} {
    flex: 0 0 100%;
    max-width: 100%;
  }

  h4 {
    @media #{layout.$breakpoint-l} {
      margin: {
        bottom: 20px;
        left: 10px;
      }
    }
  }
}
